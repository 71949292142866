import '@gcx-si/vue-components/main.css'
import App from '@/App.vue'
import { router } from '@/setup/router.ts'
import { i18nPlugin } from '@/setup/i18n.ts'
import { sentryPlugin } from '@/setup/sentry.ts'
import { setupOpenApi } from '@/setup/openApi.ts'
import { setupIcons } from '@/setup/icons.ts'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import PrimeVue from 'primevue/config'
import PrimeTooltip from 'primevue/tooltip'
import { vTest } from '@gcx-si/vue-components/vTest'

import '@gcx-si/vue-components/yup'

const pinia = createPinia()

setupOpenApi()
setupIcons()

createApp(App)
  .use(router)
  .use(sentryPlugin, { router })
  .use(PrimeVue)
  .use(ToastService)
  .use(ConfirmationService)
  .use(pinia)
  .use(i18nPlugin)
  .directive('tooltip', PrimeTooltip)
  .directive('test', vTest)
  .mount('#app')
