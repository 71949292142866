import { es } from 'date-fns/locale'
import { enUS as en } from 'date-fns/locale/en-US'

import type { Locale } from 'date-fns/locale'

export const I18N_DEFAULT_LOCALE = 'en'
export const I18N_LANGUAGES = [I18N_DEFAULT_LOCALE, 'es']

export const dateFnsLocales: Record<string, Locale> = {
  es,
  en,
}

export const TOAST_LIFETIME = 5_000

export enum StorageKeys {
  language = 'mecc-language',
  isSidebarExpanded = 'mecc-sidebar-expanded',
  gatewayGroupsOverview = 'mecc-gateway-groups-overview',
  gatewayGroupsDetail = 'mecc-gateway-groups-detail',
  loginRedirection = 'login-redirection'
}

export enum RouteNames {
  DEFAULT_ROUTE = 'default',
  GATEWAY_GROUPS__OVERVIEW = 'gateway_groups__overview',
  GATEWAY_GROUPS__GATEWAY_ASSIGNMENT = 'gateway_groups__gateway_assignment',
  GATEWAY_GROUPS__GROUP_DETAIL = 'gateway_groups__group_detail',
  GATEWAY_GROUPS__GATEWAY_DETAIL = 'gateway_groups__gateway_detail',
  CLOUD_ADAPTERS__OVERVIEW = 'cloud_adapters__overview',
  CLOUD_ADAPTERS__CLOUD_ADAPTER_DETAIL = 'cloud_adapters__cloud_adapter_detail',
  API_DOCUMENTATION = 'api_documentation',
  LOGIN = 'login',
}

export const SECONDS_ONE_MINUTE = 60
export const SECONDS_ONE_HOUR = 60 * SECONDS_ONE_MINUTE
export const SECONDS_ONE_DAY = 24 * SECONDS_ONE_HOUR
export const SECONDS_ONE_WEEK = 7 * SECONDS_ONE_DAY
