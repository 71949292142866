import { RouteNames } from '@/setup/constants.ts'

import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

export const RoutesCloudAdapters: RouteRecordRaw[] = [
  {
    path: 'cloud-adapter',
    meta: {
      navigation: {
        labelKey: 'navigation.cloud_adapter.label',
        targetName: RouteNames.CLOUD_ADAPTERS__OVERVIEW,
      },
    },
    children: [
      {
        path: '',
        name: RouteNames.CLOUD_ADAPTERS__OVERVIEW,
        component: retriableLazyImport(() => import('./PageOverview.vue')),
      },
      {
        path: ':cloudAdapterId',
        name: RouteNames.CLOUD_ADAPTERS__CLOUD_ADAPTER_DETAIL,
        component: retriableLazyImport(() => import('./PageCloudAdapterDetail.vue')),
      },
    ],
  },
]
