import { I18N_DEFAULT_LOCALE } from '@/setup/constants.ts'
import { useUserStore } from '@/stores/useUserStore.ts'

import { createI18n } from 'vue-i18n'
import axios from 'axios'
import { watchEffect } from 'vue'
import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'
import { createYupLocale } from '@gcx-si/vue-components/createYupLocale'

import type { App } from 'vue'
import type { MessageFunction } from 'vue-i18n'

type JSONLocaleModule = { default: Record<string, MessageFunction> }

const i18n = createI18n({
  legacy: false,
  locale: I18N_DEFAULT_LOCALE,
  fallbackLocale: I18N_DEFAULT_LOCALE,
})

function setLocaleLanguage(locale: string) {
  i18n.global.locale.value = locale
  axios.defaults.headers.common['Accept-Language'] = locale
  document.documentElement.setAttribute('lang', locale)
}

async function loadLocaleMessages(locale: string) {
  const loadLanguage = retriableLazyImport<JSONLocaleModule>(() => import(`@/locales/${locale}.json`))
  const messages = await loadLanguage()

  i18n.global.setLocaleMessage(locale, messages.default)

  createYupLocale(messages.default, i18n.global.t)
}
export function i18nPlugin(app: App) {
  const userStore = useUserStore()

  app.use(i18n, {})

  watchEffect(async () => {
    if (!userStore.language) {
      return
    }

    await loadLocaleMessages(userStore.language)
    return setLocaleLanguage(userStore.language)
  })
}
