import { type GatewayOperatorResponse, getAuthenticatedOperator } from '@/api'
import { I18N_DEFAULT_LOCALE, StorageKeys } from '@/setup/constants.ts'

import { defineStore } from 'pinia'
import { useAsyncStoreRef } from '@gcx-si/vue-components/useAsyncStoreRef'
import { useLocalStorageRef } from '@gcx-si/vue-components/useLocalStorageRef'

export interface ExtendedGatewayOperatorResponse extends GatewayOperatorResponse {
  decryption?: boolean
}

export const useUserStore = defineStore('userStore', () => {
  const language = useLocalStorageRef(StorageKeys.language, I18N_DEFAULT_LOCALE)
  const { getter: getGatewayOperator, reset: resetGatewayOperator } = useAsyncStoreRef<ExtendedGatewayOperatorResponse>(getAuthenticatedOperator)

  return {
    language,
    getGatewayOperator,
    resetGatewayOperator,
  }
})
