import { config } from '@/setup/config.ts'

import { createBeforeSendHandler } from '@gcx-si/vue-components/createBeforeSendHandler'
import { createBeforeSendTransactionHandler } from '@gcx-si/vue-components/createBeforeSendTransactionHandler'
import { monkeyPatchDedupe } from '@gcx-si/vue-components/monkeyPatchDedupe'
import {
  init,
  browserTracingIntegration,
  replayIntegration,
  extraErrorDataIntegration,
  httpClientIntegration
} from '@sentry/vue'

import type { Router } from 'vue-router'
import type { Integration } from '@sentry/types'
import type { App } from 'vue'

export function sentryPlugin(app: App, options: { router: Router }) {
  init({
    app,
    dsn: config.SENTRY_DSN,
    // do not configure release here, see vite.config.ts
    logErrors: false,
    maxBreadcrumbs: 50,
    normalizeDepth: 10,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.2,
    environment: config.SENTRY_ENVIRONMENT,
    debug: false,
    beforeSend: createBeforeSendHandler(app, import.meta.env.DEV),
    beforeSendTransaction: createBeforeSendTransactionHandler(import.meta.env.DEV),
    sendDefaultPii: true,
    integrations: function (integrations) {
      monkeyPatchDedupe(integrations)

      return [
        ...integrations,
        httpClientIntegration(),
        extraErrorDataIntegration(),
        browserTracingIntegration({
          router: options.router,
        }),
        replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
          maxReplayDuration: 120_000,
          block: [
            '[data-sentry-block]',
          ],
        }),
      ] as Integration[]
    },
  })
}
